<template>
  <div id="topPart">
    <div class="top">
      <div class="topHeader">
        <div class="topHeaderMain">
          <div class="topHeaderMainLeft">
            甘肃省建设投资（控股）集团有限公司
          </div>
          <div class="topHeaderMainRight">
            <span style="margin-right: 10px;"> <i class="iconfont">&#xe7b2;</i> 智慧建投 </span>
            <!-- <a href="/groupProfile/organization" style="color: #a2a2a2; font-size: 14px;"><span> <i class="iconfont">&#xe62e;</i> 建投站群 </span></a> -->
          </div>
        </div>
      </div>
      <div class="topLogo">
        <div class="topLogoMain">
          <div class="topLogoMainLeft">
            <a href="/" class="logoImgPc"><img src="../../assets/image/Nlogo.png" class="Nlogo"/></a>
            <a href="/" class="logoImgMobile"><img src="../../assets/image/Nlogo_mobile.png" class="Nlogo"/></a>
          </div>
          <div class="topLogoMainRight">
            <div class="header_search">
              <div>
                <el-input v-model="searchValue" placeholder="请输入标题" style="width: 300px;"></el-input>
                <el-button
                  type="submit"
                  class="btn "
                  style="background-color:#0d65af; border-radius:0px;"
                  @click="handleSearch"
                  >搜索</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="topMenu">
        <div class="topMenuMain">
          <el-menu
            :default-active="route"
            class="el-menu-demo"
            background-color="rgba(0,0,0,0)"
            text-color="#ffffff"
            active-text-color="rgb(108, 171, 231)"
            mode="horizontal"
            :router="true"
          >
            <div
              style="display:inline-block"
              :class="{ active: fullpath == item.url.split('/')[1] }"
              v-for="(item, index) in navSubList"
              :key="index"
            >
              <el-menu-item :index="item.url">{{ item.name }}</el-menu-item>
              <span class="span1" v-if="index < navSubList.length - 1"></span>
              <span class="span2"></span>
            </div>
          </el-menu>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNavChannel } from '@/api/common'

export default {
  name: '#',
  data() {
    return {
      searchValue: '',
      fullpath: '/homePage',
      navSubList: [],
      navList: [
        // {
        //   id: 1,
        //   name: '网站首页',
        //   index: '/homePage'
        // },
        {
          id: 2,
          name: '集团概况',
          index: '/groupProfile'
        },
        {
          id: 3,
          name: '新闻中心',
          index: '/pressCenter'
        },
        {
          id: 4,
          name: '业务板块',
          index: '/businessUnit'
        },
        {
          id: 5,
          name: '党的建设',
          index: '/partyBuilding'
        },
        // {
        //   id: 6,
        //   name: '文化频道',
        //   index: '/culture'
        // },
         {
          id: 6,
          name: '信息公开',
          index: '/information'
        },
        {
          id: 7,
          name: '甘肃建投报',
          index: '/newsPaper'
        },
        {
          id: 8,
          name: '联系我们',
          index: '/contactUs'
        }
      ]
    }
  },
  computed: {
    route() {
      return this.$store.state.route
    }
  },
  mounted() {
    var _this = this

    _this.getNavChannel()
    _this.fullpath = _this.$route.path.split('/')[1]
  },
  methods: {
    handleSearch() {
      var _this = this
      if (!_this.searchValue || _this.searchValue == '') {
        this.$message({
          message: '请输入关键词',
          type: 'warning'
        })
      } else {
        window.location.href = `/search/${_this.searchValue}`
        // window.open(`/search/${_this.searchValue}`)
      }
    },
    // 获取一级导航
    getNavChannel() {
      var _this = this

      let data = {
        applicationUuid: _this.webId
      }
      getNavChannel(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          data.length &&
            data.forEach((line, index) => {
              if (index < _this.navList.length) {
                if (line.child.length) {
                  line.url = _this.navList[index].index + '/' + line.child[0].entTChannelUuid
                } else {
                  line.url = _this.navList[index].index + '/' + line.entTChannelUuid
                }
              }
            })
          _this.navSubList = [
            {
              id: 1,
              name: '网站首页',
              url: '/'
            },
            ...data.slice(0, 7)
          ]
        }
      })
    }
  },
  watch: {
    $route(val) {
      this.fullpath = val.path.split('/')[1]
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'iconfont'; /* Project id 3165120 */
  src: url('//at.alicdn.com/t/font_3165120_6n6ymhzsl3g.woff2?t=1643337039153') format('woff2'),
    url('//at.alicdn.com/t/font_3165120_6n6ymhzsl3g.woff?t=1643337039153') format('woff'),
    url('//at.alicdn.com/t/font_3165120_6n6ymhzsl3g.ttf?t=1643337039153') format('truetype');
}

@font-face {
  font-family: 'iconfont'; /* project id 3165259 */
  src: url('');
  src: url('?#iefix') format('embedded-opentype'),
    url('//at.alicdn.com/t/font_3165259_4zwhtyd54xp.woff2') format('woff2'),
    url('//at.alicdn.com/t/font_3165259_4zwhtyd54xp.woff') format('woff'),
    url('//at.alicdn.com/t/font_3165259_4zwhtyd54xp.ttf') format('truetype'), url('#iconfont') format('svg');
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

.topHeader {
  width: 100%;
  height: auto;
  background-color: #f1f6fa;
  overflow: hidden;
  color: #0162b3;
  font-size: 14px;
}
.topHeaderMain {
  width: 1200px;
  margin: 0 auto;
  height: 31px;
  line-height: 31px;
}
.topHeaderMainLeft {
  display: block;
  float: left;
  width: 340px;
  overflow: hidden;
}
.topHeaderMainRight {
  display: block;
  float: right;
  overflow: hidden;
  color: #a2a2a2;
}
.topLogo {
  overflow: hidden;
  padding: 10px 0px;
}
.topLogoMain {
  width: 1200px;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
}
.topLogoMainLeft {
  width: 710px;
  overflow: hidden;
  margin-top: 11px;
  margin-bottom: 12px;
}
.topLogoMainLeft {
  float: left !important;
}
.topLogoMainRight {
  width: 380px;
  height: 50px;
  margin-top: 45px;
  overflow: hidden;
}
.topLogoMainRight {
  float: right !important;
}
.header_search {
  width: 380px;
  height: 51px;
  overflow: hidden;
  float: right;
  position: relative;
}
.header_search input {
  width: 300px;
  height: 38px;
  overflow: hidden;
  outline: none;
  box-shadow: none;
  line-height: 38px;
  font-size: 14px;
  color: #585858;
  border-radius: 0px;
  border: solid 1px #0d65af;
}

.form-control {
  display: block;
  padding: 6px 12px;
}
.btn {
  display: inline-block;
  height: 38px;
  line-height: 38px;
  padding: 0px 12px;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  font-size: 14px;
  border: none;
  cursor: pointer;
}
.topMenu {
  height: 50px;
  background-color: #0162b3;
  overflow: hidden;
  border-bottom: 1px #ffffff solid;
}
.topMenuMain {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
  text-align: center;
}
.el-menu-item {
  width: 148px;
  height: 60px;
  line-height: 52px;
  text-align: center;
  float: left;
  color: #ffffff;
  display: block;
  font-size: 16px;
  font-weight: 600;
}
.el-menu-item:hover {
  background-color: rgba(7, 6, 63, 0.425) !important;
}
.el-menu--horizontal > .el-menu-item {
  float: left;
  height: 60px;
  line-height: 52px;
  margin: 0;
  border-bottom: 2px solid transparent;
}
.el-menu .span1 {
  width: 2px;
  height: 50px;
  background: url(../../assets/image/menu_line.jpg) center center no-repeat;
  display: inline-block;
}
.el-menu .span2 {
  width: 2px;
  height: 50px;
  display: inline-block;
}
.topMenuMain .active .el-menu-item {
  color: #fff;
  background-color: rgba(7, 6, 63, 0.425) !important;
}
.logoImgMobile {
  display: none;
}
@media (max-width: 1300px) {
  .el-menu-item {
    width: 120px;
  }
  .topHeaderMain,
  .topLogoMain {
    width: 1000px;
  }
  .topLogoMainLeft {
    width: 500px;
  }
  .topLogoMainLeft img {
    width: 100%;
  }
  .topLogoMainRight {
    margin-top: 26px;
  }
}
@media (max-width: 992px) {
  .topHeaderMain,
  .topLogoMain {
    width: 94%;
  }
  .topLogoMainLeft {
    width: 50%;
  }
  .topLogoMainRight {
    width: 46%;
  }
  .header_search {
    width: 100%;
  }
  .header_search .el-input {
    width: 80% !important;
  }
  .header_search input {
    width: 100%;
  }
  .el-menu-item {
    width: 100px;
    padding-left: 0;
    padding-right: 0;
  }
  .el-menu .span1,
  .el-menu .span2 {
    display: none;
  }
}
@media (max-width: 850px) {
  .el-menu-item {
    width: 88px;
  }
}
@media (max-width: 768px) {
  .topHeader {
    display: none;
  }
  .topLogoMainLeft {
    width: 100%;
    text-align: center;
  }
  .topLogoMainLeft img {
    width: 90%;
  }
  .topLogoMainRight {
    width: 100%;
    margin-top: 0;
  }
  .header_search .el-input {
    width: calc(100% - 54px) !important;
  }
  .topLogo {
    padding: 0;
  }
  .el-menu.el-menu--horizontal {
    width: auto;
    overflow-x: auto;
    white-space: nowrap;
    border-bottom: none;
    padding: 0;
  }
  .topMenuMain .el-menu-item {
    height: 50px !important;
    margin: 0 1px;
    border-radius: 4px;
    padding-bottom: 8px;
  }
  .el-menu .span1,
  .el-menu .span2 {
    display: none;
  }
  .topMenu {
    height: auto;
    padding-top: 6px;
  }
  .logoImgPc {
    display: none;
  }
  .logoImgMobile {
    display: block;
  }
}
@media (prefers-color-scheme: dark) {
  .logoImgPc {
    display: none;
  }
  .logoImgMobile {
    display: block;
  }
  // #topPart::before {
  //   display: block;
  //   width: 100%;
  //   height: 40px;
  //   content: '我是暗黑模式了啊';
  // }
}
</style>
