<template>
  <!-- <div class="index" :class='{appGrey : isGrey}'> -->
    <div class="index">
    <div class="topImg" ref="topImg">
      <!-- <a href="/" target="_blank" v-if="viewImgTop"> -->
        <!-- <img src="../../assets/image/beijin2.jpg" alt="" style="width: 100%" /> -->
        <img :src="content.fileName" :alt="content.title" style="width: 100%" />
      <!-- </a> -->
    </div>

    <div class="container" :style="containerStyle">
      <topPart />
      <router-view  />
      <bottomPart />
    </div>
  </div>
</template>

<script>
import { getAdvert } from '@/api/common'
// @ is an alias to /src
import topPart from '@/components/topPart'
import bottomPart from '@/components/bottomPart'
export default {
  name: 'Index',
  data() {
    return {
      containerStyle: {},
      viewImgTop: true,
      content: '',
      dateKey:new Date(),
      isGrey: false,
      fullpath: '/homePage',
    }
  },
  components: {
    topPart,
    bottomPart
  },
  computed: {
    route() {
      return this.$store.state.route
    }
  },
  mounted() {
    // this.srollimg()
    let url = this.$route.name
    if (url == 'homePage') {
      this.isGrey = true
      this.srollimg()
    }
    else{
      this.isGrey = false
    }

  },
  methods: {
    srollTop() {
      var _this = this
      var heightT
      setTimeout(() => {
        heightT = this.$refs.topImg.offsetHeight
        _this.containerStyle = {
          marginTop: heightT + 'px'
        }
      }, 500)
      setTimeout(() => {
        _this.containerStyle = {
          marginTop: 0
        }
      }, 3000)
      setTimeout(() => {
        _this.viewImgTop = false
      }, 6000)
    },

    srollimg() {
      var _this = this
      let data = {
        applicationUuid: _this.webId,
        advertType: 4
      }
      getAdvert(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          data.length && (_this.content = data[0])
          this.$nextTick(() => {
            this.srollTop()
          })
        }
      })
    }
  },
  watch: {
    $route(val) {
      if(val.path == '/'){
        this.isGrey = true
      }
      else{
        this.isGrey = false
      }
    }
  }
}
</script>

<style lang="scss">
.appGrey {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: url("data:image/svg+xml;utf8,#grayscale");
  // filter: progid: DXImageTransform.Microsoft.BasicImage(grayscale=1);
 -webkit-filter: grayscale(1);
}
body {
  margin: 0;
  padding: 0;
  font-family: "微软雅黑", "Microsoft YaHei",sans-serif;
  font-size: 14px;
  background-color: #fff;
}
.index {
  background-color: #fff;
}
.topImg {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -10;
  overflow: hidden;
  -moz-transition: all 1s ease-in;
  -webkit-transition: all 1s ease-in;
  -o-transition: all 1s ease-in;
  transition: all 1s ease-in;
}

.topImg img {
  width: 100%;
  display: block;
}

.container {
  -moz-transition: all 1s ease-in;
  -webkit-transition: all 1s ease-in;
  -o-transition: all 1s ease-in;
  transition: all 1s ease-in;
  z-index: 10;
  background-color: #fff;
}
// @media (max-width: 1300px) {
//   .topHeaderMain,
//   .topLogoMain {
//     width: 1000px;
//   }
//   .topLogoMainLeft {
//     width: 500px;
//   }
//   .topLogoMainLeft img {
//     width: 100%;
//   }
//   .topLogoMainRight {
//     margin-top: 26px;
//   }
// }
// @media (max-width: 992px) {
//   .topHeaderMain,
//   .topLogoMain {
//     width: 94%;
//   }
//   .topLogoMainLeft {
//     width: 50%;
//   }
//   .topLogoMainRight {
//     width: 46%;
//   }
//   .header_search {
//     width: 100%;
//   }
//   .header_search .el-input {
//     width: 80% !important;
//   }
//   .header_search input {
//     width: 100%;
//   }
//   .el-menu-item {
//     width: 100px;
//     padding-left: 0;
//     padding-right: 0;
//   }
// }
// @media (max-width: 850px) { 
//   .el-menu-item {
//     width: 88px;
//   }
// }
</style>
