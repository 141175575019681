<template>
  <div style="width: 100% !important;">
    <div class="foot">
      <div class="footMain">
        <div class="Left"><img src="../../assets/image/jtewm.jpg" /><br />甘肃建投微信公众号</div>
        <div class="Left"><img src="../../assets/image/wxewm.jpg" /><br />甘肃建设报</div>

        <div class="Right footLink">
          <div class="link">
            <el-select v-model="value" @change="selectChange" placeholder="----友情链接----" style="width: 258px ;">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
            <el-select
              v-model="value2"
              @change="selectChange2"
              placeholder="----行业链接----"
              style="width: 258px ; margin-left: 50px;"
            >
              <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="rightText" style="font-size: 14px;">
            <span>Copyright © 2019 甘肃省建设投资（控股）集团有限公司&nbsp;&nbsp;&nbsp;&nbsp; </span>
            <!-- <span>访问次数：429673 &nbsp;&nbsp;</span> -->
            <span>
              <a href="https://www.cnzz.com/stat/website.php?web_id=1278584134" target="_blank" title="站长统计">
                <img border="0" hspace="0" vspace="0" src="http://icon.cnzz.com/img/pic.gif" />
              </a>
            </span>
            <br />
            <a href="http://beian.miit.gov.cn" target="_blank" style="color:#FFFFFF">陇ICP备09003141号-2</a>
            <a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=62010302000267"
              target="_blank"
              style="color:#FFFFFF"
              >甘公网安备：62010302000267号</a
            >
            <br />
            地址：兰州市七里河区西津东路575号建工大厦 &nbsp;&nbsp;电话：0931-2216267
            <br />
            <div class="enw">本站样式已版权保护，不得镜像与仿制 违者必究 Copyright © 2019 All Rights Reserved.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value:'----友情链接----',
      value2:'----行业链接----',
      options: [
        {
          value: 'http://gzw.gansu.gov.cn/',
          label: '甘肃省政府国资委'
        },
        {
          value: 'http://zjt.gansu.gov.cn/',
          label: '甘肃省住房和城乡建设厅'
        }
      ],

      options2: [
        {
          value: 'http://www.sdjiangong.com.cn',
          label: '山东建工集团'
        },
        {
          value: 'http://www.gdceg.com',
          label: '广东建工集团'
        },
        {
          value: 'http://www.huashi.sc.cn/',
          label: '四川华西集团有限公司'
        },
        {
          value: 'http://www.zjsjg.com',
          label: '浙江建工集团有限责任公司'
        },
        {
          value: 'http://www.jxsjgjt.com',
          label: '江西建工集团'
        },
        {
          value: 'http://www.aceg.com.cn',
          label: '安徽建工集团'
        },
        {
          value: 'http://www.jsce.com.cn/index/index.asp',
          label: '江苏建工集团'
        },
        {
          value: 'http://www.shxi-jz.com',
          label: '陕西建工集团总公司'
        },
        {
          value: 'http://www.sxjgjt.com.cn/',
          label: '山西建工（集团）总公司'
        },
        {
          value: 'http://www.scg.com.cn',
          label: '上海建工集团有限公司'
        }
      ]
    }
  },

  mounted() {
    // this.getNum()
  },
  methods: {
    selectChange(value) {
      console.log(value), window.open(this.value)
    },
    selectChange2(value2) {
      console.log(value2), window.open(this.value2)
    }
  }
}
</script>

<style lang="scss" >
.foot {
  background-color: #0263b4;
  color: #ffffff;
  text-align: center;
  line-height: 25px;
  padding: 10px;
}
.footMain {
  width: 1200px;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
}

.footMain .Left {
  width: 130px;
  float: left;
  overflow: hidden;
  margin-left: 30px;
  line-height: 25px;
  height: 127px;
  color: #76a6ca;
  text-align: center;
  font-size: 13px;
  margin-top: 20px;
}

.footMain .Left img {
  width: 90px;
  height: 90px;
}
.footMain .Right {
  width: 800px;
  float: left;
  margin-left: 50px;
  text-align: left;
  line-height: 35px;
}
.footMain .footLink {
  height: auto;
}
.footMain .footLink .el-input__inner {
  background: none;
  border: 1px solid #9194a7;
  color: #ffffff;
  text-indent: 10px;
  line-height: 30px;
  font-size: 12px;
  height: 30px;
  margin-top: 10px;
  border-radius: 0px;
}
.el-input__suffix {
  position: absolute;
  top: 4px;
  height: 100%;
  color: #ffffff;
  text-align: center;
}
.el-popper[x-placement^='bottom'] {
  margin-top: 8px;
}
.el-select-dropdown {
  border: 1px solid #e4e7ed;
  border-radius: 1px;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  box-sizing: border-box;
  margin: 5px 0;
}
.el-select-dropdown__item {
  font-size: 12px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #222;
  height: 24px;
  line-height: 24px;
  box-sizing: border-box;
  cursor: pointer;
}

.el-select-dropdown__item.selected {
  color: #fff;
  background-color: rgb(30,144,255);
}
.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: rgb(30,144,255);
  color: #fff;
}
.el-select .el-input .el-select__caret {
  color: #ffffff;
}
.foot .enw {
  color: #76a6ca;
  text-align: left;
  font-size: 13px;
  display: inline-block;
  width: 100%;
  margin-top: 0px;
}
@media (max-width: 1300px) {
  .footMain {
    width: 1000px;
  }
  .footMain .Right {
    width: 626px;
  }
}
@media (max-width: 992px) {
  .footMain {
    width: 96%;
  }
  .footMain .Left {
    width: 42%;
  }
  .footMain .Right {
    width: 90%;
  }
}
@media (max-width: 768px) {
  .footMain {
    padding-top: 10px;
  }
  .footMain .Left {
    width: 50%;
    margin: 0;
  }
  .footMain .Right {
    width: 100%;
    margin: 0;
    text-align: center;
  }
  .footMain .Right .el-select {
    width: 44% !important;
    margin: 0 !important;
    margin: 0 2% !important;
  }
  .footMain .Right .rightText {
    margin-top: 10px;
    line-height: 24px;
    font-size: 14px;
  }
  .foot .enw {
    text-align: center;
  }
}
</style>
